<template>
  <b-tabs
    vertical
    nav-wrapper-class="nav-vertical"
  >
    <b-tab
      active
      title="常用包裹尺寸设置"
    >
      <b-card style="min-height:500px">
        <parcel-size />
      </b-card>
    </b-tab>
    <b-tab title="线路设置">
      <b-card>
        <line-setting />
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BCard,
} from 'bootstrap-vue'
import ParcelSize from './components/parcelSize.vue'
import LineSetting from './components/lineSetting.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    ParcelSize,
    LineSetting,
  },
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <h5>以下为客户到店可选择的物流，您可以查看成本，和设置对外计算方式和价格</h5>
    <h5 class="mt-2">
      系统在提交时，会根据客户填写信息和包裹尺寸自动判断可使用的线路
    </h5>
    <b-table
      bordered
      class="mt-4"
      :fields="fields"
      :items="items"
    >
      <template #cell(action)="data">
        <button
          type="button"
          class="btn btn-flat-primary"
          style="padding: 5px;"
          @click="$router.push(`/logisticsdetail/${data.item.courier_id}`)"
        >
          查看
        </button>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable,
} from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  data() {
    return {
      fields: [{
        key: 'courier_id', label: '线路ID',
      }, {
        key: 'courier_name', label: '线路名称',
      }, {
        key: 'action', label: '操作',
      }],
      items: [{
        courier_id: '59',
        courier_name: '澳洲邮寄台湾集运线路',
      }],
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button
        v-b-modal.modal-lg
        variant="relief-primary"
      >
        新增
      </b-button>
    </div>
    <b-table
      bordered
      class="mt-2"
      :fields="fields"
      :items="items"
    >
      <template #cell(size)="data">
        <span class="text-nowrap">
          {{ data.item.length }} * {{ data.item.width }} * {{ data.item.height }}
        </span>
      </template>
      <template #cell(action)>
        <button
          type="button"
          class="btn btn-flat-primary"
          style="padding: 5px;"
        >
          编辑
        </button>
        <button
          type="button"
          class="btn btn-flat-danger"
          style="padding: 5px;"
        >
          删除
        </button>
      </template>
    </b-table>
    <b-modal
      id="modal-lg"
      ok-only
      ok-title="保存"
      centered
      size="lg"
      title="新增/编辑"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="箱子名称"
              label-for="h-first-name"
              label-cols-md="2"
            >
              <b-form-input
                id="h-first-name"
                style="width:50%"
                placeholder="箱子名称"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="箱子尺寸"
              label-for="h-email"
              label-cols-md="2"
            >
              <b-form-input
                id="h-email"
                style="width:50%"
                placeholder="箱子尺寸"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="箱子默认属性"
              label-for="h-email"
              label-cols-md="2"
            >
              <b-form-select
                id="parcel-type"
                style="width:50%"
                :options="typeList"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BTable, BModal, BForm, BRow, BCol, BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BTable,
    BModal,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  data() {
    return {
      fields: [{
        key: 'name', label: '箱子名称',
      }, {
        key: 'size', label: '箱子尺寸',
      }, {
        key: 'type', label: '箱子默认属性',
      }, {
        key: 'action', label: '操作',
      }],
      items: [{
        name: '一罐箱',
        length: '20',
        width: '20',
        height: '40',
        type: '保健品',
      }],
      typeList: [{
        value: 1,
        text: '保健品',
      }],
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
